import React from 'react';
import { MantineProvider } from '@mantine/core';
import BookingConfirmation from './BookingConfirmation/BookingConfirmation'
import { Loader } from '@mantine/core';
import { Center } from '@mantine/core';


import axios from 'axios';

function Gagan() {

  const urlParams = new URLSearchParams(window.location.search);
  const gigId = urlParams.get("gigId");
  const [gigData, setGigData] = React.useState();
  const [isLoading, setIsloading] = React.useState(false);
  React.useEffect(() => {
    setIsloading(true);
    console.log('hello');
    axios.post('https://api.retool.com/v1/workflows/3008f1fc-0d17-42e7-ab3b-e215484dc3cf/startTrigger?workflowApiKey=retool_wk_b04b7837d079496997a3b0d824762cc2', {gigId}).then((res) => {setIsloading(false); setGigData(res.data.data)})
  },[gigId])

  if(isLoading) return <Center><Loader /></Center>

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <BookingConfirmation gigData={gigData}/>
    </MantineProvider>
  );
}

export default Gagan;