import { Text, Group, Flex, SimpleGrid, Container, Title } from "@mantine/core";
import styled from "@emotion/styled";
// import { IconMail } from "@tabler/icons-react";
import {StyledSection} from './BookingDetails';
import ShiftDetails from './ShiftDetails';
import AddressDetails from './AddressDetails';
import React from "react";

const PRIMARY_TEXT_COLOR = "#73769a";
const SECONDARY_TEXT_COLOR = "#3e4066";

const SERVICE_FEE_PERCENT = 20;
const SUMMER_PROMOTION_DISCOUNT_PERCENT = 50;

// interface Props {
//   gigData: GigData;
//   techData: AxiosData;
// }

// export interface GigData {
//   gigById: {
//     id: string;
//     name: string;
//     description: string;
//     type: "PHYSICAL" | "HYBRID" | "VIRTUAL";
//     location: string;
//     timezone: string;
//     dates: string[];
//     jobs: {
//       id: string;
//       gigRole: {
//         id: string;
//         name: string;
//       };
//       shifts: {
//         id: string;
//         date: string;
//         startsAt: string;
//         endsAt: string;
//         dailyRate: string;
//         notes: string;
//       }[];
//       dailyBudget: number;
//       notes: string;
//       paymentTermsTemplate: string;
//     }[];
//   };
// }

const BookingConfirmationPage = ({ gigData }) => {
  const dailyRateInDollars = gigData?.gigById?.jobs[0]?.dailyBudget / 100;
  const numberOfBillableDays = gigData?.gigById?.jobs[0]?.shifts?.length;

  const subtotal = dailyRateInDollars * numberOfBillableDays;
  const serviceFeeAmount = subtotal * (SERVICE_FEE_PERCENT / 100);
  const serviceFeeWithDiscount2 =
    serviceFeeAmount * (SUMMER_PROMOTION_DISCOUNT_PERCENT / 100);
  const estimatedTotal =
    subtotal +
    serviceFeeAmount -
    serviceFeeWithDiscount2;

    console.log('booking confirm');

  if (!gigData) return <div></div>;

  return (
    <>
      <Container size="xs" p={15} pt={0} mt={80}>
        <BookingSummaryHeader>
          <Text size="sm" fw={500}>
            Your Booking Summary
          </Text>
        </BookingSummaryHeader>
        <BookingSummary>
          <StyledSection title="Booking Details">
            <Container size="lg" p={20} pt={25}>
              <SimpleGrid cols={2} mb={20}>
                <Flex direction="column">
                  <Text size="xs" fw={700} mb={5} c={SECONDARY_TEXT_COLOR}>
                    Role
                  </Text>
                  <Text mb="xl" c={PRIMARY_TEXT_COLOR}>
                    {gigData.gigById?.jobs[0]?.gigRole?.name}
                  </Text>
                </Flex>
                <Flex direction="column">
                  <Text size="xs" fw={700} mb={5} c={SECONDARY_TEXT_COLOR}>
                    Gig Name
                  </Text>
                  <Text mb="sm" c={PRIMARY_TEXT_COLOR}>
                    {gigData.gigById?.name}
                  </Text>
                </Flex>
              </SimpleGrid>
              <PricingTableHeader>
                <Text size="sm" fw={500}>
                  Freelancer Price Breakdown
                </Text>
              </PricingTableHeader>
              <PricingTable direction="column">
                <LineItem position="apart" c={PRIMARY_TEXT_COLOR}>
                  <Text size="sm">Daily Rate</Text>
                  <Text size="sm">
                    &#36;{dailyRateInDollars} x {numberOfBillableDays}
                    {numberOfBillableDays === 1 ? " day" : " days"}
                  </Text>
                </LineItem>
                <LineItem position="apart" c={PRIMARY_TEXT_COLOR}>
                  <Text size="sm">Subtotal</Text>
                  <Text size="sm">&#36;{subtotal}</Text>
                </LineItem>
                <LineItem position="apart" c={PRIMARY_TEXT_COLOR}>
                  <Text size="sm">
                    Service Fee &#40;{SERVICE_FEE_PERCENT}&#37;&#41;
                  </Text>
                  <Text size="sm">&#36;{serviceFeeAmount}</Text>
                </LineItem>
                <LineItem position="apart" c={PRIMARY_TEXT_COLOR}>
                  <Text size="sm">
                    Summer Discount &#40;expires 9/15/23&#41;
                  </Text>
                  <Text size="sm">&minus; &#36;{serviceFeeWithDiscount2}</Text>
                </LineItem>
                <Group position="apart" p="14px 0 0" c={SECONDARY_TEXT_COLOR}>
                  <Text size="sm">Estimated Total</Text>
                  <Text size="sm">&#36;{estimatedTotal}</Text>
                </Group>
              </PricingTable>
            </Container>
          </StyledSection>
          <ShiftDetails shifts={gigData.gigById?.jobs[0]?.shifts} />
          <AddressDetails address={`${gigData.gigById?.location}`} />
        </BookingSummary>
      </Container>
      {/* <Container
        size="md"
        mt={50}
        mb={80}
        bg="#111827"
        sx={{ borderRadius: "10px" }}
      >
        <SimpleGrid cols={2} p="50px 40px">
          <Flex direction="column">
            <Title size={50} color="white" mb={20}>
              Need to change your Gig Details?
            </Title>
            <Text size="lg" color="white">
              Manage and edit all your booking easily through our web app.
            </Text>
            <Group mt={50}>
              <Button
                size="lg"
                radius="md"
                mr="30px"
                styles={(theme) => ({
                  root: {
                    color: "#262626",
                    backgroundColor: "#00f85d",
                    "&:hover": theme.fn.hover({
                      backgroundColor: theme.fn.lighten("#00f85d", 0.3),
                    }),
                  },
                })}
                onClick={() => {}}
              >
                Sign Up
              </Button>
              <Button
                radius="md"
                size="lg"
                variant="outline"
                color="white"
                sx={{ borderColor: "#00f85d", color: "#fff" }}
                onClick={() => {}}
              >
                Log In
              </Button>
            </Group>
          </Flex>
        </SimpleGrid>
      </Container> */}
    </>
  );
};

const BookingSummary = styled.div`
  padding: 0 24px 24px;
  border: 1px solid #e1e1e7;
  border-radius: 0 0 8px 8px;
`;

const BookingSummaryHeader = styled(Group)`
  padding: 8px 24px;
  background: #f5f8fa;
  color: #3e4066;
  border: 1px solid #e1e1e7;
  border-bottom: 0;
  border-radius: 8px 8px 0 0;
`;

const PricingTable = styled(Flex)`
  padding: 5px 24px 24px;
  border: 1px solid #e1e1e7;
  border-radius: 0 0 8px 8px;
`;

const PricingTableHeader = styled(Group)`
  padding: 8px 15px 8px 24px;
  background: #f5f8fa;
  color: #3e4066;
  border: 1px solid #e1e1e7;
  border-bottom: 0;
  border-radius: 8px 8px 0 0;
`;

const LineItem = styled(Group)`
  padding: 14px 0;
  border-bottom: 1px solid #e1e1e7;
`;

export default BookingConfirmationPage;
