import { Container, Flex, Grid, Space, Text } from "@mantine/core";
import styled from "@emotion/styled";
import { format } from "date-fns";

import { StyledSection } from "./BookingDetails";

// interface Shift {
//   id: string;
//   date: string;
//   startsAt: string;
//   endsAt: string;
//   dailyRate: string;
//   notes: string;
// }

// interface Props {
//   shifts?: Shift[];
// }

const ShiftDetails = ({ shifts }) => {
  return (
    <StyledSection title="Dates and Times">
      <Container size="lg" p={15} pt={25}>
        <CardContainer>
          {shifts?.map((shift, index) => (
            <Card key={shift.id} index={index}>
              <Grid>
                <Grid.Col span="content">
                  <ShiftNumber>
                    {index < 10 && "0"}
                    {index + 1}
                  </ShiftNumber>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Flex direction="column">
                    <Text fw={600} size="sm" mb="sm" c="#3e4066">
                      {format(new Date(shift.startsAt), "MM-d-yyyy")}
                    </Text>
                    <Text size="xs" fw={700} mb={5} c="#3e4066">
                      Start Time
                    </Text>
                    <Text mb="sm" c="#73769a">
                      {format(new Date(shift.startsAt), "h:mm a")}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col offset={1} span={4}>
                  <Space h="2.25rem" />
                  <Flex direction="column">
                    <Text size="xs" fw={700} mb={5} c="#3e4066">
                      End Time
                    </Text>
                    <Text mb="sm" c="#73769a">
                      {format(new Date(shift.endsAt), "h:mm a")}
                    </Text>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Card>
          ))}
        </CardContainer>
      </Container>
    </StyledSection>
  );
};

const ShiftNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin: 0 10px 10px 0;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50%;
  background: #f3f4f6;
  color: #3e4066;
`;
const Card = styled.div`
  padding: 15px;
  border-top: ${({ index }) => (index !== 0 ? "1px solid #e1e1e7" : "0")};
`;

const CardContainer = styled.div`
  border: 1px solid #e1e1e7;
  border-radius: 10px;
`;
export default ShiftDetails;
